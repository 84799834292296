import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  constructor(private dataService: DataService) { }

  getTimesheetListByUserId(refresh, userId, index) {
    return this.dataService.getData('/api/timesheet/' + userId + '/' + index, refresh);
    // const query = { Id: userId, Index: index };
    // return this.dataService.getDataWithParams('/api/timesheet', query, refresh);
  }

  saveTimesheets(data) {
    return this.dataService.post('/api/timesheet', { timesheets: data });
  }

  updateDefaultProjectByUserId(projectId) {
    return this.dataService.post('/api/employee', { projectId });
  }

  getDashboardData(refresh, userId) {
    return this.dataService.getData('/api/timesheet/chart/' + userId, refresh);
    // return this.dataService.getData('/api/timesheet/chart?Id=' + userId, refresh);
  }

  getAttendanceListByUserId(refresh, userId, index) {
    return this.dataService.getData('/api/timesheet/inout/' + index, refresh);
    // const query = { Id: userId, Index: index };
    // return this.dataService.getDataWithParams('/api/attendance', query, refresh);
  }

  saveAttendances(data) {
    return this.dataService.post('/api/timesheet/inout', { Attendances: data });
  }
}
