import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserSessionService } from './services/usersession.service';
import { DataService } from './services/data.service';
import { AlertService } from './services/alert.service';
import { LookupService } from './services/lookup.service';
import { NavigationService } from './services/navigation.service';
import { HttpInterceptorService } from './services/interceptor.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Device } from '@ionic-native/device/ngx';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TimesheetService } from './services/timesheet.service';
import { ChartsModule } from 'ng2-charts';
import { SpinnerComponent } from './shared/component/spinner/spinner.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AddteamPageModule } from './pages/addteam/addteam.module';
import { ProjectService } from './services/project.service';
//import { FCM } from '@ionic-native/fcm/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DatePipe } from '@angular/common';
import { File } from '@ionic-native/file/ngx';

@NgModule({
  declarations: [AppComponent, SpinnerComponent],
  entryComponents: [SpinnerComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, BrowserAnimationsModule,
    HttpClientModule, NgHttpLoaderModule.forRoot(),
    ChartsModule, AddteamPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    Device,
    StatusBar,
    SplashScreen,
    Network,
    AppVersion,
    // FCM,
    HttpInterceptorService,
    File,
    DatePipe,
    // AuthGuard,
    NavigationService,
    UserSessionService,
    LookupService,
    TimesheetService,
    ProjectService,
    AlertService,
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
