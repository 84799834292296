import { Component, OnInit } from '@angular/core';
import { UserSessionService } from 'src/app/services/usersession.service';
import { LookupService } from 'src/app/services/lookup.service';
import { PopoverController } from '@ionic/angular';
import { ProjectService } from 'src/app/services/project.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-addteam',
  templateUrl: './addteam.page.html',
  styleUrls: ['./addteam.page.scss'],
})
export class AddteamPage implements OnInit {

  selectedProjectId: number;
  selectedEmployeeId: number;
  projectList: any[];
  employeeList: any[];

  constructor(private lookupService: LookupService,
    private modalController: PopoverController,
    private projectEmployeeService: ProjectService,
    private toastService: AlertService,
    private userSessionService: UserSessionService) {
    this.loadProjects();
    this.loadEmployee();
  }

  ngOnInit() {
  }

  loadProjects() {
    const userId = this.userSessionService.userId();
    this.projectList = [];
    this.lookupService.getAllProjectList(true, userId).subscribe((result) => {
      if (result) {
        this.projectList = result;
      }
    });
  }

  loadEmployee() {
    const userId = this.userSessionService.userId();
    this.employeeList = [];
    this.lookupService.getAllEmployees(true, userId).subscribe((result) => {
      if (result) {
        this.employeeList = result;
      }
    });
  }

  save() {
    const item = { projectEmployeeViewModel: { projectId: this.selectedProjectId, employeeId: this.selectedEmployeeId, isDeleted: false } };
    this.projectEmployeeService.saveProjectEmployee(item).subscribe((result) => {
      if (result) {
        this.modalController.dismiss(true);
        this.toastService.success('Member assigned successfully');
      }
    });
  }

  cancel() {
    this.modalController.dismiss(false);
  }
}
