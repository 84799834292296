export const environment = {
  // apiBaseUrl: 'http://139.59.46.102:8053',
  //apiBaseUrl: 'http://localhost:5080',
  // apiBaseUrl: 'http://13.71.114.225:',
  apiBaseUrl: 'https://eazypmv2api.xenovex.com',
  tokenEndPoint: '/api/token',
  signalrUrl: 'http://yenbus.com:8001/signalr/hubs',
  imageUrl: 'http://api.yenbus.com:8003/',
  production: false,
  environment: 'Local',
  showEnvironment: true,
  firebase: {
    //apiKey: 'AIzaSyAAy_iwTEAAZjCRCP8_CWSblmLGhYdTgSg',
    apiKey: 'AIzaSyB6yGkqRaAZgHn_BqleZQE0wxpsycO-zSU',
    vapidKey: 'BKliDGyhij4OEEkPdbs2RjjeGkzT2mUwCNLZ7xjLUN0XUHRvfH4_6bdR--7AOKJ6iKGBxVxmwjUvQwwodbOBMUw',
    authDomain: 'AUTH_DOMAIN',
    databaseURL: 'https://eazy-tm.firebaseio.com',
    projectId: 'eazy-tm',
    storageBucket: 'eazy-tm.appspot.com',
    messagingSenderId: '206887300907',
    appId: '1:206887300907:android:5530b2ee5ec2e23359df6e',
  }
};