import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserSession } from '../models/usersession';
import { UserSessionService } from './usersession.service';
import * as momenttz from 'moment-timezone';
import { DataService } from './data.service';
import * as forge from "node-forge";

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  authenticationState = new BehaviorSubject(false);
  baseUrl = environment.apiBaseUrl;
  timeZones: any[];
  sessionData = new UserSession();
  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAruzidSHRz8Pk6wy/aw3z
  U1lp+pB6BGU1LxsWGJMsJ2dRCks5+G3MBGttwOHwIMd42+PTy4tIwxBg3yI7yf2C
  mOKz+7UaG/pVQII4HfRmMxN7K5W9lf6+GSnmCdxDBtWc/4/AtRNrwHkDHwwK6bPn
  mysKZ9ymfxAT3MxKE9HpOUPaZzqqGEw7LbGW9nIWpAiBcbRTgvGRDsOuq4XVVDka
  v8H06TRgFrUtJ1HffIdW4XDJ/hUbAtzD3sLwomH4o0cLv/gnntf6HZNs51axB5Ep
  qIWXPSyHHd6Wi6Suij9/PDmrhTfOQSrrLrGxtOvchGO5H6IxGJJEhPYbozlGvJ90
  iQIDAQAB
  -----END PUBLIC KEY-----`;

  constructor(public router: Router,
    private sessionService: UserSessionService,
    private dataService: DataService,
    private http: HttpClient) {
    // this.getTimeZones();
  }

  // getTimeZones() {
  //   this.timeZones = timezone.timeZone;
  // }

  login(username: string, password: string, isLogin: boolean) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      });

    const timeZone = 'India Standard Time'; // this.getBrowserTimeZone();
    //const data = 'username=' + username + '&password=' + password + '&timeZone=' + timeZone; //+ '&param=' + deviceDetails;
    
    var rsa = forge.pki.publicKeyFromPem(this.publicKey);
    var encryptedPassword = window.btoa(rsa.encrypt(password));

    const data = {
      username,
      password: encryptedPassword,
      timeZone: timeZone,
      isForceLogout: isLogin,
    }
    console.log(data);
    return this.http.post<any>(this.baseUrl + '/api/token', data, { headers: headers })
      .map(user => {
        if (user && user.access_token) {
          const decodedToken = jwtDecode(user.access_token);
          this.sessionData.authToken = user.access_token;
          this.sessionData.userId = decodedToken['user.id'];
          this.sessionData.roleId = decodedToken['user.roleid'];
          this.sessionData.userFullName = decodedToken['user.fullname'];
          this.sessionData.projectId = decodedToken['user.projectid'];
          this.sessionData.email = decodedToken['referrence2'];
          this.sessionData.mobileNo = decodedToken['referrence3'];
          this.sessionData.version = decodedToken['mobileversion'];
          this.sessionData.isCriticalUpdate = decodedToken['iscriticalupdate'];
          this.sessionService.create(this.sessionData);
        }
        return this.sessionData;
      });
  }

  getBrowserTimeZone(): string {
    const zoneName = momenttz.tz.guess();
    const timezone1 = momenttz.tz(zoneName).zoneAbbr();
    const filterZone = this.timeZones.filter(i => i.abbr === timezone1)[0];
    if (filterZone) {
      return filterZone.value;
    }
    return '';
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  logOut() {
    // localStorage.clear();
    this.dataService.clearCache();
    this.sessionService.destroy();
    this.router.navigate(['login']);
    this.authenticationState.next(false);
  }
}
