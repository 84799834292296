import { Component, ViewChildren, QueryList, OnInit } from '@angular/core';

import {
  Platform, ToastController, NavController, AlertController,
  IonRouterOutlet, ModalController, ActionSheetController, PopoverController
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AlertService } from './services/alert.service';
import { SpinnerComponent } from './shared/component/spinner/spinner.component';
import { AuthenticationService } from './services/authentication.service';
import { NavigationService } from './services/navigation.service';
//import { FCM } from '@ionic-native/fcm/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
// import { firebase } from '@firebase/app';
import { environment } from 'src/environments/environment';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  spinnerComponent = SpinnerComponent;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  counter: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    //private fcm: FCM,
    private appVersion: AppVersion,
    public toastCtrl: ToastController,
    public alertController: AlertController,
    private alertService: AlertService,
    private navCtrl: NavController,
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private notificationsService: NotificationsService,
    public modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController
  ) {
    this.initializeApp();
    // this.handleHardwareBackButton();
    this.backButtonEvent();
    const isLoggedinAlready = localStorage.getItem('isLoggedinAlready');
    if (isLoggedinAlready && isLoggedinAlready === '1') {
      this.autoLogin();
    } else {
      // this.initializeNotification();
      // // firebase.initializeApp(environment.firebase);
      // // this.notificationsService.init();
    }
  }

  async ngOnInit() {
    // if (!firebase.apps.length) {
    //   firebase.initializeApp(environment.firebase);
    //   await this.notificationsService.init();
    // }

    // window['isUpdateAvailable']
    //   .then(isAvailable => {
    //     if (isAvailable) {
    //       this.alertService.toast('New Update available! Reload the app to see the latest juicy changes.');
    //     }
    //   });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString('#2E4C7C');
      this.splashScreen.hide();
      await this.notificationsService.requestPermission();
    });
  }

  autoLogin() {

    const user = localStorage.getItem('username');
    const pwd = localStorage.getItem('password');
    const devicedata = localStorage.getItem('devicedetails');
    this.appVersion.getVersionNumber().then((version) => {
      const devicedetails = JSON.parse(devicedata);
      devicedetails.fcmToken = localStorage.getItem('FCMToken');
      this.authService.login(user, pwd, devicedetails).subscribe((res) => {
        if (version === res.version) {
          this.navigationService.goToLayout();
        }
      });
    });
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(async () => {
      // close action sheet
      try {
        const element = await this.actionSheetCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
      }

      // close popover
      try {
        const element = await this.popoverCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
      }

      // close modal
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
        console.log(error);

      }

      // // close side menua
      // try {
      //   const element = await this.menu.getOpen();
      //   if (element !== null) {
      //     this.menu.close();
      //     return;

      //   }

      // } catch (error) {

      // }

      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        // if (outlet && outlet.canGoBack()) {
        //   outlet.pop();
        // } else {
        // console.log('=========================');
        console.log(this.router.url);
        // else if (this.router.url === '/login' || this.router.url.includes('/tabs/')) {
        if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
          // this.platform.exitApp(); // Exit from app
          navigator['app'].exitApp(); // work for ionic 4
        } else {
          this.alertService.toast('Press back again to exit App.');
          this.lastTimeBackPress = new Date().getTime();
        }
        // }
      });
    });
  }

  private handleHardwareBackButton(): void {
    document.addEventListener('backbutton', () => {
      if (this.router.url.includes('/tabs/')) {
        this.presentAlertConfirmExit();
      } else if (this.router.url === '/login') {
        navigator['app'].exitApp();
      } else {
        this.navCtrl.pop();
      }
    }, false);
  }
  async presentAlertConfirmExit() {

    const alert = await this.alertController.create({
      header: 'Message',
      message: 'Are you sure want to Exit the App?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Okay',
          handler: () => {
            if (this.counter === 0) {
              this.counter++;
              this.presentToast();
              setTimeout(() => { this.counter = 0; }, 3000);
            } else {
              navigator['app'].exitApp();
            }

          }
        }
      ]
    });
    await alert.present();
  }
  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Press again to exit',
      duration: 2000,
      position: 'middle',
    });
    toast.present();
  }

  // initializeNotification() {
  //   this.fcm.getToken().then(token => {
  //     localStorage.setItem('FCMToken', token);
  //   });

  //   this.fcm.onNotification().subscribe(data => {
  //     // if (data.wasTapped) {
  //     //   console.log("Received in background");
  //     // } else {
  //     //   console.log("Received in foreground");
  //     // };
  //   });

  //   this.fcm.onTokenRefresh().subscribe(token => {
  //     console.log('--------------2----------------' + token);
  //     localStorage.setItem('FCMToken', token);
  //   });
  // }
}
