import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()

export class NavigationService {

  constructor(private router: Router) {
  }

  isOnLoginScreen(): boolean {
    return this.router.url === '/login';
  };

  goToLayout() {
    this.router.navigateByUrl('/tabs');
  }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }

}

