import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ModuleType } from '../enum/moduletype';

@Injectable()
export class LookupService {

  constructor(private dataService: DataService) { }


  getProjectListByUserId(refresh, userId) {
    return this.dataService.getData('/api/lookup/' + ModuleType.EmployeeProject, refresh);
    // return this.dataService.getData('/api/lookup/project?Id=' + userId + '&moduleType=' + ModuleType.EmployeeProject, refresh);
  }

  getTaskTypeList(refresh: boolean) {
    return this.dataService.getData('/api/lookup/' + ModuleType.TaskType, refresh);
  }

  // getProjectListByUserId(refresh, userId) {
  //   return this.dataService.getData('/api/lookup/project?Id=' + userId, refresh);
  // }

  getAllProjectList(refresh, userId) {
    return this.dataService.getData('/api/lookup/' + ModuleType.Project, refresh);
    // return this.dataService.getData('/api/lookup/project?Id=' + userId + '&moduleType=' + ModuleType.Project, refresh);
  }

  getAllEmployees(refresh, userId) {
    return this.dataService.getData('/api/lookup/' + ModuleType.Employee, refresh);
    //return this.dataService.getData('/api/lookup/employee?Id=' + userId + '&moduleType=' + ModuleType.Employee, refresh);
  }
}
