import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';

@Injectable()
export class DataService {

  constructor(private http: HttpClient) {
  }

  private baseUrl = environment.apiBaseUrl;
  private cache: any = {};

  getData(route, refresh) {
    route = this.baseUrl + route;
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else { // no cached data or refresh requested
      return this.http.get<any>(route).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  }
  putDataWithParams(route, params, refresh) {
    route = this.baseUrl + route;
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else { // no cached data or refresh requested
      return this.http.put(route, { params }).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  }

  getDataWithParams(route, params, refresh) {
    route = this.baseUrl + route;
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else { // no cached data or refresh requested
      return this.http.get<any>(route, { params }).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  }

  getRecord(route) {
    route = this.baseUrl + route;
    return this.http.get<any>(route);
  }

  getRecordWithParams(route, params) {
    route = this.baseUrl + route;
    return this.http.get<any>(route, { params });
  }

  post(route, data) {
    route = this.baseUrl + route;
    return this.http.post<any>(route, data).map(response => {
      this.cache[route] = response;
      console.log(response);
      return response;
    });
  }

  delete(route) {
    route = this.baseUrl + route;
    return this.http.delete(route).map(response => {
      return response;
    });
  }

  getReport(route) {
    route = this.baseUrl + route;
    return this.http.get(route, { responseType: 'blob' });
  }

  getResponseAsText(route) {
    route = this.baseUrl + route;
    return this.http.get(route, { responseType: 'text' });
  }

  dataForRouteIsCached(route, refresh) {
    route = this.baseUrl + route;
    return this.cache[route] && (refresh === false || refresh === undefined);
  }

  clearCache() {
    this.cache = {};
  }

  clearRouteCache(route) {
    this.cache[route] = null;
  }

  getHttpParams(data: any) {
    let httpParams = new HttpParams();
    Object.keys(data).forEach(function (key) {
      httpParams = httpParams.append(key,
        data[key]
      );
    });
    return data;
  }
}
