export class UserSession {
    userId: number;
    roleId: number;
    userFullName: string;
    authToken: string;
    projectId: number;
    email: string;
    mobileNo: string;
    isCriticalUpdate: string;
    version: string;
}
