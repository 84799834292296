import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public toastController: ToastController,
              public alertController: AlertController) { }

  async toast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  success(message: string) {
    this.toastController.create({
      message,
      showCloseButton: true,
      position: 'bottom',
      animated: true,
      cssClass: 'toast-success',
      duration: 2000
    }).then((toastData) => {
      toastData.present();
    });
  }

  info(message: string) {
    this.toastController.create({
      message,
      showCloseButton: true,
      position: 'bottom',
      animated: true,
      closeButtonText: 'OK',
      cssClass: 'toast-info',
      duration: 2000
    }).then((toastData) => {
      toastData.present();
    });
  }

  warning(message: string) {
    this.toastController.create({
      message,
      showCloseButton: true,
      position: 'middle',
      animated: true,
      closeButtonText: 'OK',
      cssClass: 'toast-warn'
    }).then((toastData) => {
      toastData.present();
    });
  }

  alert(message: string) {
    this.toast(message);
  }

  public async error(message: string) {
    // const toast = await this.toastController.create({
    //   message,
    //   duration: 2000
    // });
    // toast.present();
    this.toastController.create({
      message,
      showCloseButton: true,
      position: 'middle',
      animated: true,
      closeButtonText: 'OK',
      cssClass: 'toast-error'
    }).then((toastData) => {
      toastData.present();
    });
  }

  async alertWhite(message: string) {
    const alert = await this.alertController.create({
      header: 'Alert',
      // subHeader: 'Subtitle',
      message,
      buttons: ['OK']
    });

    await alert.present();
  }
}
