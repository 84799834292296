import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class ProjectService {

  constructor(private dataService: DataService) { }

  getProjectEmployeesByEmpId(refresh, empId) {
    // const query = { Id: empId };
    // return this.dataService.getDataWithParams('/api/projectemployee', query, refresh);
    return this.dataService.getData('/api/projectemployee', refresh);
  }

  saveProjectEmployee(data) {
    return this.dataService.post('/api/projectemployee', data);
  }
}
